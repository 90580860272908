import React, { FC } from 'react'

import { PageLayout } from 'components/PageLayout/PageLayout'
import { useRouter } from 'next/router'
import { Typography, TypographyType } from '../../components/DataDisplay/Typography/Typography'
import { useTranslation } from '../../hooks/useTranslationDeprecated'
import { Button } from '../../components/Inputs/Button/Button'
import styles from './NotFound.module.scss'
import { Icon, IconColor, IconName } from '../../components/DataDisplay/Icon/Icon'
import { Link } from '../../components/Link/Link'
import { Routes } from '../../services/router/Routes'

export const NotFound: FC = () => {
  const { t } = useTranslation()
  const { asPath } = useRouter()

  const isPropertyNotFoundError = asPath.startsWith('/properties')

  return (
    <PageLayout>
      <div className={styles.container}>
        <Icon className={styles.houseSmileFloating} name={IconName.NEUTRAL_EMOTION} color={IconColor.RED} size={40} />
        <Typography typographyType={TypographyType.H1_TITLE}>
          {isPropertyNotFoundError ? t.notFoundPage.property : t.notFoundPage.somethingWentWrong}
        </Typography>
        <Typography className={styles.description}>
          {isPropertyNotFoundError ? t.notFoundPage.propertyDescription : t.notFoundPage.description}
        </Typography>
        <Link route={Routes.properties({})}>
          <Button className={styles.button}>{t.notFoundPage.backToHome}</Button>
        </Link>
      </div>
    </PageLayout>
  )
}
